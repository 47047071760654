import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Authentication`}</h2>
    <p>{`The API uses JWT token for authentication. The API accepts the token in the
`}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header, in the following format:
`}<inlineCode parentName="p">{`Authorization: eyJhbGciOiJIUzI1NiIsInR5cCI6Ik...0RMHrHDcEfxjoYZgeFONFh7HgQ`}</inlineCode>{`
This header must be included with every request.`}</p>
    <h2>{`Obtaining the Access token`}</h2>
    <p>{`You can get JWT token from the OAuth2 token endpoint by setting the `}<inlineCode parentName="p">{`token_type`}</inlineCode>{` parameter to `}<inlineCode parentName="p">{`jwt`}</inlineCode>{`. `}<strong parentName="p">{`This parameter must be set as HTTP POST parameter.`}</strong></p>
    <p>{`The OAuth2 flows are described here:
`}<a parentName="p" {...{
        "href": "/api-basics-authentication"
      }}>{`Basic Authentication`}</a></p>
    <p>{`The recommended authentication flow is `}<em parentName="p">{`Client credentials`}</em>{`.`}</p>
    <h2>{`Time format`}</h2>
    <p>{`Every time field provided by the API is formatted by ISO8601 format (eg. `}<inlineCode parentName="p">{`2018-07-16T19:20:30+01:00`}</inlineCode>{`).
The API also accepts parameters in this format only.`}</p>
    <h2>{`Terminology`}</h2>
    <ul>
      <li parentName="ul"><em parentName="li">{`media`}</em>{`: Media is a common word for both live broadcasts and recorded videos`}</li>
      <li parentName="ul"><em parentName="li">{`content type`}</em>{`: The API has knowledge of two types of content that are live
broadcasts and recorded videos`}</li>
      <li parentName="ul"><em parentName="li">{`content id`}</em>{`: A numeric identifier of the media. For live it means channel ID,
for video it means video ID`}</li>
      <li parentName="ul"><em parentName="li">{`segment`}</em>{`: A part of the media watched by the user`}</li>
    </ul>
    <h2>{`Response times`}</h2>
    <p>{`Depending on your query and the underlying data size the response might take a minute.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      